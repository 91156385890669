@use 'sass:math';
@import 'styles/includes.scss';

.CardEvent {
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }

    $root: &;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;

    opacity: 0;
    transform: translateY(50px) scale(1);
    transition: transform 0.4s ease-out, opacity 0.5s ease-out;

    &--Loaded,
    &--Shown {
        opacity: 1;
        transform: translateY(0px) scale(1);
        transition: transform 0.4s ease-out, opacity 0.5s ease-out;
        transition-delay: 0;
    }

    &--MyList {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        @include media(ml) {
            padding: 2.4rem !important;
        }

        &#{$root}--Line:first-of-type {
            border-top: none !important;
        }
    }

    &:hover {
        .CardEventPicture__DateBackground--Medium,
        .CardEventPicture__Picture--Small img {
            transform: scale(1.1) rotate(-40deg);
        }
        .CardEventPicture__Picture--RightCol img,
        .CardEventPicture__Picture--Medium img,
        .CardEventPicture__Picture--Small img,
        .CardEventPicture__Picture--Large img {
            transform: scale(1.1);
        }
    }

    @include media(l) {
        min-width: calc((#{map-get($maxwidths, l)} - 72px) / 4);
    }

    @include media(xl) {
        min-width: calc((#{map-get($maxwidths, xl)} - 72px) / 4);
    }

    &--Pointer {
        cursor: pointer;
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1001;

        &:focus {
            outline: none;
            & ~ .CardEventPicture {
                box-shadow: $blueShadow;
            }
        }
    }


    &__IconWrapper {
        margin-right: $spacingS;
        display: flex;
        align-items: center;
        margin-top: -0.1rem;

        &--Place {
            margin-right: $spacingS - 1px;
            margin-left: -1px;
        }

        .CardEvent--Loader & {
            display: none;
        }

        @include media(m) {
        }
    }

    &__Content {
        color: $colorWhite;
        font-family: $fontBrand;
    }

    &__RemoveButton {
        background: none;
        border: none;
        z-index: 1002;

        &:hover,
        &:focus {
            outline: 1px dotted $colorPink;
        }
    }

    &__Title {
        display: block;
        font-size-adjust: none;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 10px 0 10px;
        line-height: 1.41;
        color: $colorWhite;
        letter-spacing: 0.2px;

        @include media(m) {
            margin: 16px 0 10px;
            font-size: 1.6rem;
            line-height: 1.68;
            letter-spacing: 0.4px;
            max-width: 100%;
        }
        #{$root}--White & {
            color: black;
        }
        #{$root}--Loader & {
            height: 32px;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f7f8;
            background: linear-gradient(
                to right,
                #eeeeee 8%,
                #dddddd 18%,
                #eeeeee 33%
            );
            background-size: 1000px 104px;
            position: relative;
            z-index: 1;
        }
    }

    &__Label {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.26rem;
        color: $colorGray700;
        margin-bottom: 1.1rem;

        @include media(ml) {
            font-size: 1.4rem;
            color: $colorBlack800;
            margin-bottom: 1.9rem;
        }
    }

    &__Category {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 2.6px;
        color: $colorGray200;

        @include media(m) {
            font-size: 1.4rem;
        }

        #{$root}--White & {
            color: $colorGray700;
        }
    }

    &__Bookmark {
        position: absolute;
        right: 50px;
        width: 44px;
        height: 34px;
        background: none;
        border: none;
        z-index: 1001;
        display: none;

        @include media(s) {
            display: inline-block;
        }

        &--Saved {
            padding-left: 12px;
        }
    }

    &__Meta {
        display: flex;
        gap: 0.4rem;
        flex-direction: column;

        .CardEvent--Loader & {
            margin-top: 4px;
        }
    }
    &__MetaRow {
        display: flex;
        flex-direction: row;
        font-size: 1.2rem;
        line-height: 1.6;
        color: $colorGray700;
        align-items: center;
        font-size-adjust: none;

        @include media(m) {
            font-size: 1.4rem;
            line-height: 1.65;
        }

        .Icon {
            margin-right: 10px;
        }

        #{$root}--Black & {
            color: white;
        }

        #{$root}--Mixed & {
            color: white;
        }

        #{$root}--Loader & {
            height: 26px;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f7f8;
            background: linear-gradient(
                to right,
                #eeeeee 8%,
                #dddddd 18%,
                #eeeeee 33%
            );
            background-size: 1000px 104px;
            position: relative;
            z-index: 1;
        }

        #{$root}--White & {
            color: $colorGray700;
        }
    }

    &--Line {
        border-top: 1px solid $colorGray200;
    }

    &--PaddingVertical {
        padding-top: $spacingL;
        padding-bottom: $spacingL;
    }

    &--Active {
        @include media(ml) {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            border-bottom: 4px solid $colorPink;
            box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15);
            background-color: $colorGray40;
        }
    }
    
    &--MyList {
        &#{$root}--Active {
            border-bottom: 4px solid $colorPink;
            background-color: $colorGray40;
        }

        #{$root}__Title {
            padding-right: 8rem;

            @include media(m) {
                padding-right: 0;
            }
        }
    }
    

    &--Horizontal {
        flex-direction: row;
        max-width: none;

        #{$root}__Content {
            color: $colorBlack;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;

            @include media(m) {
                padding-left: $spacingXl;
            }
        }

        #{$root}__Title {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.43;
            letter-spacing: 0.2px;
            margin: 0;
            color: $colorBlack;

            @include media(m) {
                font-size: 1.9rem;
                line-height: 1.68;
                letter-spacing: 0.4px;
                margin-bottom: 6px;
                max-width: calc(100% - 69px);
            }
        }

        #{$root}__Category {
            font-size: 1.2rem;
            color: $colorGray700;
            margin-bottom: 10px;
            letter-spacing: 2.6px;

            @include media(m) {
                margin-top: 5px;
                font-size: 1.4rem;
                margin-bottom: 16px;
            }
        }

        #{$root}__RemoveButton {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            font-family: $fontBrand;
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: $colorGray500;
            padding: 0.2rem;
            text-decoration: underline;
        }

        #{$root}__IconSize {
            width: 1rem;
            margin-right: 0.6rem;
        }

        #{$root}__MetaRow {
            font-size: 1.2rem;
            margin-top: 0;
            margin-bottom: 5px;
            line-height: 1.6;
            color: $colorGray700;
        }

        #{$root}__IconWrapper {
            margin-right: $spacingS;
            margin-top: 0;
        }

        #{$root}__Description {
            color: $colorBlack750;
            display: none;
            font-size: 1.6rem;
            line-height: 1.65;
            margin-bottom: 10px;

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @include media(m) {
                display: block;
            }
        }

        .CardEventPicture__PictureLabel {
            display: none;
        }

        .CardEventPicture {
            width: 9rem;
            @include media(m) {
                width: 21.6rem;
            }
        }
    }

    &--Loader .CardEventPicture.CardEventPicture {
        aspect-ratio: 1;
        // min-height: 400px;
        // width: 100%;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(
            to right,
            #eeeeee 8%,
            #dddddd 18%,
            #eeeeee 33%
        );
        background-size: 1000px 104px;
        position: relative;
        z-index: 1;
    }

    .CardEventPicture {
        position: relative;
        max-width: 100%;
        flex: 0 0 auto;

        &__CategoryText {
            font-size: 1.2rem;
            font-size-adjust: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 80px;

            @include media(m) {
                text-overflow: initial;
                max-width: 100%;
                font-size: 1.4rem;
            }
        }

        &__CategoryContainer {
            display: flex;

            position: absolute;
            top: -0.5px;
            left: -0.5px;
            align-items: center;
            gap: 8px;
            padding: 2px 38px 2px 8px;
            z-index: 1;
            background: $colorPurple50;
            color: #000;
            height: 30px;
            clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0% 100%);

            @include media(m) {
                height: 38px;
                padding: 0 50px 0 12px;
                clip-path: polygon(0 0, 100% 0, calc(100% - 38px) 100%, 0% 100%);
            }
        }

        &--Small {
            width: 9rem;

            @include media(l) {
                width: 21.6rem;
            }
        }

        &--Medium {
            width: 100%;
            @include media(l) {
                width: 100%;
            }
        }

        &--Large {
            width: 60.1rem;
        }

        &__Picture {
            height: 0;
            position: relative;
            overflow: hidden;

            &--Small {
                padding-top: calc(90 / 90 * 100%);

                @include media(l) {
                    padding-top: calc(216 / 216 * 100%);
                }
            }

            &--Medium {
                padding-top: calc(243 / 253 * 100%);

                @include media(l) {
                    padding-top: calc(312 / 297 * 100%);
                }
            }

            &--Large {
                padding-top: calc(397 / 601 * 100%);
            }

            &--RightCol {
                padding-top: calc(90 / 90 * 100%);
                @include media(l) {
                    padding-top: calc(268 / 404 * 100%);
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            transition: transform 0.5s;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &__PictureLabel {
            line-height: 1.3;
            background-color: $colorBlack800;
            color: $colorWhite;
            font-family: $fontBrand;
            padding: 7px 24px;
            font-size: 1.4rem;
            border-radius: 20px;
            display: inline-block;
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }
}
